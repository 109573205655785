import React from "react";
import { useBrand } from "~zipdeal-ui/contexts-and-providers/BrandProvider.tsx";

export const ThreeDotsPulse = ({
	overrideColor = undefined,
	size = "w-2.5 h-2.5",
}: {
	overrideColor?: string;
	size?: string;
}) => {
	const { brand, brandColor, accentColor } = useBrand();

	const color =
		accentColor === brandColor
			? "bg-white"
			: overrideColor || `bg-accent-${brand}`;

	return (
		<div className={"flex"}>
			<div
				className={`inline-block animate-pulseGrow rounded-full  ${size} ${color} mx-1`}
			/>
			<div
				className={`inline-block animate-pulseGrow rounded-full  ${size} ${color} mx-1 animation-delay-250`}
			/>
			<div
				className={`inline-block animate-pulseGrow rounded-full  ${size} ${color} animation-delay-500 mx-1`}
			/>
		</div>
	);
};
